// color them
$main-bg-color: hsl(12, 45%, 4%);
$main-text-color: #ffffff;
$main-inverted-text-color: rgba(255,255,255,.6);
$main-weak-text-color: rgba(255,255,255,.6);
$main-inverted-bg-color: #ffffff;
$main-highlighted-bg-color: #ebebeb;
$main-highlighted-text-color: #ebebeb;

// variables
$top-bar-height: 160px;
$mobile-top-bar-height: 80px;
$logo-width: 160px;
