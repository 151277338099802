@import './variables.scss';

@font-face {
  font-family: 'Microsoft JhengHei';
  src: url('../fonts/Microsoft-JhengHei.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'HelveticaNeue-CondensedBold';
  src: url('../fonts/Helvetica-Neue-Condensed-Bold_22500.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Abel-regular';
  src: url('../fonts/Abel-Regular.ttf') format('truetype');
  font-weight: normal;
}


html, body, #app, #app>div {
  font-family: "Abel-regular", Arial, sans-serif;
  font-weight: 400;
}

.frame {
  font-family: "HelveticaNeue-CondensedBold", "Helvetica Neue";
}

.fullscreen {
  width: 100vw;
  height: 100vh;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

* {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.body {
  background: $main-bg-color;
  color: $main-text-color;

  & :hover {
    color: #fff;
  }
}

.content {
  margin: 0 auto 0 auto;
  @media all and (min-width: 1024px) {
    & {
      max-width: 70vw;
    }
  }
  @media all and (min-width: 768px) and (max-width: 1024px) {
    & {
      max-width: 80vw;
    }
  }
  @media all and (min-width: 480px) and (max-width: 768px) {
    & {
      max-width: 90vw;
    }
  }
  @media all and (max-width: 480px) {
    & {
      max-width: 95vw;
    }
  }
}
