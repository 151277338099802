.loadingContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: -100;
  background-color: #000000;
}

.loadingIcon {
    z-index: -100;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
}
/* =Elements style
---------------------- */
.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #4b9cdb;
}


.ball-holder {
  position: absolute;
  width: 12px;
  height: 45px;
  left: 17px;
  top: 0px;
}

.ball {
  position: absolute;
  top: -11px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #4282B3;
}

.square {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: #f7f7f7;
}

.spinner {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.bubble-1,
.bubble-2 {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #f7f7f7;
}

.bubble-2 {
  top: auto;
  bottom: 0;
}

.bar {
  float: left;
  width: 15px;
  height: 6px;
  border-radius: 2px;
  background-color: #f7f7f7;
}

/* =Animate the stuff
------------------------ */
.loadingIcon .square {animation: loadingG 1.5s cubic-bezier(.17,.37,.43,.67) infinite;}

@keyframes loadingA {
  0% {height: 15px;}
  50% {height: 35px;}
  100% {height: 15px;}
}

@keyframes loadingB {
  0% {width: 15px;}
  50% {width: 35px;}
  100% {width: 15px;}
}

@keyframes loadingC {
  0% {transform: translate(0,0);}
  50% {transform: translate(0,15px);}
  100% {transform: translate(0,0);}
}

@keyframes loadingD {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}

@keyframes loadingE {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes loadingF {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes loadingG {
  0% {transform: translate(0,0) rotate(0deg);}
  50% {transform: translate(70px,0) rotate(360deg);}
  100% {transform: translate(0,0) rotate(0deg);}
}

@keyframes loadingH {
  0% {width: 15px;}
  50% {width: 35px; padding: 4px;}
  100% {width: 15px;}
}

@keyframes loadingI {
  100% {transform: rotate(360deg);}
}

@keyframes bounce  {
0%, 100% {transform: scale(0.0);}
50% {transform: scale(1.0);}
}
